@use '../../../../theme';

.unlock_card {

    .text {
        margin-top: 30px;
        font-size: 17px;
        color: #fff;
        font-family: SFProRoundedBold, sans-serif;
        text-align: center;
    }
   
    padding: 10px;
    .content {
        padding: 12px 15px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        @include theme.task-card-bg;
        margin-top: 20px;
        margin-bottom: 50px;
    }
   
    .num {
        display: flex;
        align-items: center;
        margin-left: -6px;
        font-family: SFProRoundedBlack, sans-serif;
        font-weight: 900;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 16px;
        letter-spacing: 1px;
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0px 2px 0px rgba(0,0,0,0.72);
        text-align: center;

        > span {
            font-size: 18px;
            margin-left: 5px;
            font-family: SFProRoundedBlack, sans-serif;
        }

        > img {
            width: 30px;
        }
    }
}

.unlock {
    height: calc(100% - 20px);
    overflow-y: scroll;
    .item {
        margin-bottom: 20px;
    }
}
