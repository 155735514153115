.invite_wrap {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .invite_top {
    margin: 10px 0;
    background: #246DA6;
    border-radius: 8px;
    font-family: SFProRoundedBlack, sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: left;
    padding: 12px 7px;
    box-sizing: border-box;
    .invite_top_item {
      display: flex;
      .invite_top_item_dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-top: 6px;
        background: #74D2FF;
        box-shadow: 0 2px 0 0 rgba(0,0,0,0.53);
        border: 1px solid #FFFFFF;
        margin-right: 5px;
      }
      .invite_top_item_text {
        flex: 1;
      }
    }
  }
  .invite_center {
    .invite_card_title {
      font-family: SFProRoundedHeavy, sans-serif;
      font-weight: 800;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 18px;
      text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,  1px 1px 0 black, 0px 2px 0px rgba(0, 0, 0, 0.72);
      text-align: left;
      font-style: normal;
      margin-bottom: 10px;
    }
    .invite_card_card {
      background: #9ACBDC;
      box-shadow: inset 0px 1px 3px 0px rgba(27,52,77,0.12);
      border-radius: 8px;
      padding: 10px;
      box-sizing: border-box;
      font-family: SFProRoundedHeavy, sans-serif;
      font-weight: 800;
      font-size: 13px;
      color: #FFFFFF;
      line-height: 15px;
      text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,  1px 1px 0 black, 0px 2px 0px rgba(0, 0, 0, 0.72);
      text-align: left;
      font-style: normal;
      margin-bottom: 10px;
    }
    .invite_btn_wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }
  }
  .invite_center1 {
    margin-top: 10px;
    .draw_card_item {
      display: flex;
      align-items: center;
      font-family: SFProRoundedHeavy, sans-serif;
      font-weight: 800;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 16px;
      text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,  1px 1px 0 black, 0px 2px 0px rgba(0, 0, 0, 0.72);
      img {
        width: 12px;
        height: 20px;
        margin-left: 5px;
      }
    }
    .draw_card_item1 {
      font-size: 13px;
      line-height: 15px;
      margin-top: 10px;
    }

    .receive_wrap{
      width: min-content;
      position: relative;
      .refresh_tips{
        position: absolute;
        top: -2px;
        right: -70px;
        width: 86px;
        height: 20px;
        line-height: 20px;
        z-index: 1;
        background: linear-gradient( 180deg, #FFC055 0%, #FF8139 100%);
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.21);
        border-radius: 12px 12px 12px 0px;
        font-family: SFProRounded, SFProRounded;
        font-weight: bold;
        font-size: 10px;
        color: #FFFFFF;
        text-align: center;
      }
    }
  }
  .invite_title {
    font-family: SFProRoundedHeavy, sans-serif;
    font-weight: 800;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 18px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .invite_bot {
    width: 100%;
    background: #246DA6;
    border-radius: 8px;
    flex: 1;
    padding: 12px 12px 0;
    box-sizing: border-box;
    .invite_bot_item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-family: SFProRoundedBold, sans-serif;
      .invite_bot_item_l {
        font-weight: bold;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 14px;
        flex: 1;
      }
      .invite_bot_item_c {
        font-weight: bold;
        font-size: 10px;
        color: #FFFFFF;
        line-height: 12px;
        flex: 1;
      }
      .invite_bot_item_r {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 800;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 16px;
        text-shadow: 0px 1px 0px rgba(0,0,0,0.72);
        text-align: right;
        flex: 0.5;
        img {
          width: 10px;
          height: 17px;
          margin-right: 5px;
        }
      }
    }
  }
}
