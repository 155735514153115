
.d_modal_wrap {
  color: #fff;
  overflow: hidden !important;
  :global {
    .ant-modal-wrap {
      overflow: hidden;
    }
    .ant-modal {
      height: 100%;
      top: 0;
      overflow: hidden;
      padding: 0;
      margin-top: 0;
      > div {
        height: 100%;
      }
      .ant-modal-content {
        height: 100%;
        padding: 0;
        background: transparent;
        border-radius: 0;
        .ant-modal-body {
          height: 100%;
        }
      }
    }
  }
  .modal_height {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
    box-sizing: border-box;
    .modal_content {
      width: 100%;
      padding-top: 40px;
      box-sizing: border-box;
      background: #4295D7;
      box-shadow: inset 0px 2px 3px 0px rgba(255,248,248,0.5), inset 0px -5px 3px 0px #2D44B7;
      border-radius: 25px;
      position: relative;
      .modal_title {
        position: absolute;
        left: 0; right: 0;
        top: -25px;
        margin: auto;
        font-family: SFProRoundedHeavy, sans-serif;
        background: url("./title_bg.png") no-repeat;
        background-size: 100% 100%;
        height: 60px;
        width: 252px;
        display: flex;
        // align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 29px;
        text-shadow: -1px -1px 0 #993900, 1px -1px 0 #993900, -1px 1px 0 #993900, 1px 1px 0 #993900, 0 2px 0 rgba(153,57,0,0.72);
        text-align: center;
        line-height: 52px;
      }
      .modal_del {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 12px; top: 12px;
      }
      .modal_content_slot {
        height: 100%;
        border-radius: 0 0 25px 25px;
        overflow: hidden;
        padding: 0 15px 20px;
        box-sizing: border-box;
      }
    }
  }
}