@use '../../../../theme';

.card {
    //background: url('../../../../assets/images/home/imomo/tenor.gif') no-repeat;
    background-size: cover;
    background-position: bottom;
    border: 2px solid theme.$card-border-color1;
    border-radius: 18px;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;

    .img_bg{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }

    .level {
        @include theme.level1;
        border: none !important;
        text-align: center;
        font-family: SFProRoundedBlack, sans-serif;
        width: 60px;
        height: 24px;
        font-weight: 900;
        font-size: 12px;
        letter-spacing: 1px;
        color: theme.$white-color;
        position: absolute;
        right: 0;
        div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 15px 0px 15px;
            border: 2px solid rgba(245,232,248,0.37);
        }
    }
    .level1 {
        @include theme.level1;
    }
    .level2 {
        @include theme.level2;
    }
    .level3 {
        @include theme.level3;
    }
    .options {
        margin:  40px auto 28px;
        display: flex;
        justify-content: center;
    }
    .time, .clock {
        @include theme.btn-green-color;
        width: 105px;
        font-size: 12px;
        position: relative;
        border-radius: 15px;
        line-height: 20px;
        height: 24px;
        text-align: center;
        div {
            border: 2px solid rgba(255,240,243,0.23);
            border-radius: 20px;
        }
        > img {
            width: 26px;
            height: 26px;
            position: absolute;
            top: -3px;
            left: -12px;
        }
    }
    .empty {
        height: 1px;
        width: 20px;
    }
    .clock {
        @include theme.btn-bule-color;
        box-shadow: none;
        border: none;
        margin-left: 5px;
    }
    .btn, .disabled {
        margin: 0 auto;
        width: 158px;
        height: 61px;
        text-align: center;
        border-radius: 19px;
        background: linear-gradient( 180deg, rgba(244, 136, 185, 0.7) 20%, rgba(255, 90, 168, 0.6) 100%);
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
        text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.5), 1px -1px 0 rgba(255, 255, 255, 0.5), -1px 1px 0rgba(255, 255, 255, 0.5), 1px 1px 0 rgba(255, 255, 255, 0.5), 0px 2px 0px rgba(255, 255, 255, 0.5);
        box-shadow: 0px 6px 4px 0px #f488b9, 0px 3px 0px 0px #f488b9, inset 0px 0px 12px 0px #f488b9;
        color: #fff;
        margin-bottom: 25px;
        transition: all .3s;
        position: relative;
        z-index: 99;
        &:active{
            opacity: 0.85;
            scale: 1.1;
        }
        .token {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: theme.$font-size;
            width: 100%;
            padding-top: 8px;
            > img {
                width: 17px;
                height: 17px;
            }
        }
    }
    .disabled {
        width: 158px;
        height: 61px;
        text-shadow: -1px -1px 0 #909090, 1px -1px 0 #909090, -1px 1px 0 #909090, 1px 1px 0 #909090, 0px 2px 0px #666666;
        background: linear-gradient( 180deg, #E5E5E5 0%, rgba(159,159,159,0.82) 100%);
        box-shadow: 0px 6px 4px 0px rgba(0,0,0,0.33), 0px 3px 0px 0px #797979, inset 0px 0px 12px 0px #FFFFFF;
        div {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 19px;
            border: 3px solid rgba(244,244,244,0.23);
        }
        span {
            margin-top: -14px;
            display: block;
        }
    }
    .lockImg {
        width: 50px;
        height: 50px;
        margin-top: -25px;
    }

    .scroll_img{
        position: absolute;
        width: 38%;
        height: 13px;
        left: 2px;
        top: calc(50% - (10px / 2));
        z-index: 1;
        div{
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden; /* 隐藏超出容器的部分 */
            .img_1{
                width: 122px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 100%; /* 图片最初位于容器的最右侧 */
                animation: scrollLeft 7s linear infinite; /* 滚动动画，10秒完成一次循环 */
            }
            .img_2{
                width: 122px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 100%; /* 图片最初位于容器的最右侧 */
                animation: scrollLeft2 7s linear infinite; /* 滚动动画，10秒完成一次循环 */
            }
        }
    }
}

.card_no{
  background: url('../../../../assets/images/home/imomo/Violet-bg.jpeg') no-repeat;
  background-size: cover;
  background-position: center;
}

@keyframes scrollLeft {
    0% {
        left: 140%; /* 图片最初位于容器的最右侧 */
    }
    100% {
        left: -100%; /* 图片向左滚动至容器的最左侧 */
    }
}

@keyframes scrollLeft2 {
    0% {
        left: 140%; /* 图片最初位于容器的最右侧 */
    }
    100% {
        left: -100%; /* 图片向左滚动至容器的最左侧 */
    }
}

.border1 {
    border: 2px solid theme.$card-border-color1;
}
.border2 {
    border: 2px solid theme.$card-border-color2;
}
.border3 {
    border: 2px solid theme.$card-border-color3;
}
