
.prompt_wrap{
  .card_title {
    font-family: SFProRounded, SFProRounded;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    font-style: normal;
    margin-bottom: 20px;
    margin-top: 35px;
  }
  .btn_wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
}
