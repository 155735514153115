@use '../../theme';
.home {
  font-size: 30px;
  .block_black {
    width: 100%;
    height: 76px;
    background: #131527;
  }
  .content {
    width: 100%;
    height: 100vh;
    background: url('../../assets/images/home/imomo/bg-imomo.jpg') no-repeat;
    background-size: cover;
    overflow-y: auto;
  }
  .list {
    width: 100%;
    .coin_avatar {
      width: 100%;
      height: 230px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .dv {
        width: 168px;
        height: 168px;
        position: relative;
      }
    }
    .wrap_ist {
      width: calc(100% - 30px);
      margin-left: 15px;
      position: relative;
      z-index: 300;
    }
    .coin {
      position: absolute;
      opacity: 0;
      animation: fall 2s forwards;
    }
    @keyframes fall {
      0% {
        opacity: 1;
        transform: translate(0, 0);
      }
      50% {
        transform: translate(var(--endX), calc(var(--endY) - 100px));
      }
      100% {
        opacity: 0;
        transform: translate(var(--endX), var(--endY));
      }
    }
    .item {
      margin-bottom: 25px;
    }
  }
  .empty {
    width: 100%;
    height: 80px;
  }
}
