@use '../../../../theme';

.unlock_card {
  padding-top: 35px;
  box-sizing: border-box;

  .text {
    font-size: 17px;
    color: #fff;
    font-family: SFProRoundedBold, sans-serif;
    text-align: center;
    margin-bottom: 35px;
  }

}

.unlock {
  height: 100%;
  overflow-y: scroll;
}
