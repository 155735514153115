.d_empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 16px;
  text-align: center;
  box-sizing: border-box;
  font-family: SFProRoundedBlack, sans-serif !important;
  .empty_bg{
    background: url("../../assets/images/home/no_data.png") no-repeat;
    width: 100px;
    height: 100px;
    background-size: contain;
    margin-bottom: 10px;
  }
}
