.extract_comp_wrap {
  width: 100%;
  height: 100%;
  .select_module_wrap_line {
    width: 100%;
    height: 10px;
  }
  .envelope_module_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .envelope_module_top {
      flex: 1;
      overflow: auto;
      .envelope_module_user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 21px;
        box-sizing: border-box;
        width: calc(100% - 34px);
        margin-left: 23px;
        height: 36px;
        background: #246DA6;
        box-shadow: inset 0px 1px 3px 0px rgba(27,52,77,0.5);
        border-radius: 8px;
        position: relative;
        font-family: SFProRoundedBlack, sans-serif;
        font-weight: 900;
        font-size: 14px;
        color: #FFFFFF;
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -19px;
          margin: auto;
          width: 38px;
          height: 38px;
        }
        .envelope_module_user_price {
          text-shadow: 0px 2px 0px rgba(0,0,0,0.72);
        }
      }
      .envelope_module_text {
        width: 100%;
        font-family: SFProRoundedBold, sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        margin: 20px 0 10px;
      }
      .select_input {
        height: 52px;
        background: #C0E4F4;
        box-shadow: 0px 2px 4px 0px #1D649F, inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -3px 1px 0px #68D3FF;
        border-radius: 15px;
        border: 1px solid #75CAFF;
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #0C4E65;
        line-height: 19px;
        text-align: left;
        padding: 10px 20px;
        box-sizing: border-box;
      }
      .select_input_none {
        height: 52px;
        background: #C0E4F4;
        box-shadow: 0px 2px 4px 0px #1D649F, inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -3px 1px 0px #68D3FF;
        border-radius: 15px;
        border: 1px solid #75CAFF;
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-align: left;
        padding: 10px 20px;
        box-sizing: border-box;
        text-shadow: none;
      }
      .select_input_none::placeholder {
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #7BB4C2;
        text-align: left;
      }
      .envelope_module_red {
        margin: 30px 0;
        font-family: SFProRoundedBold, sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        .envelope_module_num {
          margin-bottom: 10px;
          font-family: SFProRoundedHeavy, sans-serif;
          font-weight: 800;
          font-size: 30px;
          color: #FFCD76;
          line-height: 30px;
          text-shadow: 0px 3px 0px #8D6622;
        }
      }
      .envelope_module_desc {
        width: 100%;
        padding: 10px 12px;
        box-sizing: border-box;
        background: #246DA6;
        border-radius: 8px;
        font-family: SFProRoundedBold, sans-serif;
        font-weight: bold;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 16px;
      }
    }
    .select_bot_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .envelope_module_record_open {
        margin-top: 20px;
        margin-bottom: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 15px;
        color: #C4DDF1;
        line-height: 15px;
        text-shadow: 0px 2px 0px rgba(0,0,0,0.72);
        img {
          width: 7px;
          height: 10px;
          margin-left: 5px;
          margin-top: 3px;
          display: flex;
        }
      }
    }
  }
}
.envelope_public {
  .envelope_card_title {
    font-family: SFProRoundedHeavy, sans-serif;
    font-weight: 800;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 18px;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,  1px 1px 0 black, 0px 2px 0px rgba(0, 0, 0, 0.72);
    text-align: left;
    font-style: normal;
    margin-bottom: 10px;
  }
  .envelope_card_card {
    background: #9ACBDC;
    box-shadow: inset 0px 1px 3px 0px rgba(27,52,77,0.12);
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    font-family: SFProRoundedHeavy, sans-serif;
    font-weight: 800;
    font-size: 13px;
    color: #FFFFFF;
    line-height: 15px;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,  1px 1px 0 black, 0px 2px 0px rgba(0, 0, 0, 0.72);
    text-align: left;
    font-style: normal;
    margin-bottom: 10px;
  }
  .envelope_btn_wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  .envelope_desc {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SFProRoundedHeavy, sans-serif;
    font-weight: 800;
    font-size: 16px;
    color: #C4DDF1;
    line-height: 15px;
    text-shadow: 0px 2px 0px rgba(0,0,0,0.72);
    margin: 20px 0 30px;
    span {
      color: #FFFFFF;
      margin-left: 5px;
    }
  }
}
.envelope_record_list {
  height: 100%;
  width: 100%;
  background: #246DA6;
  border-radius: 8px;
  padding-top: 10px;
  overflow: auto;
  .envelope_record_item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .envelope_record_item_left {
      width: 44px;
      height: 44px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      background: red;
    }
    .envelope_record_item_center {
      flex: 1;
      overflow: hidden;
      font-family: SFProRoundedBold, sans-serif;
      .envelope_record_item_center_top {
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 14px;
        text-align: left;
      }
      .envelope_record_item_center_bot {
        margin-top: 5px;
        font-weight: bold;
        font-size: 10px;
        color: #C4DDF1;
        line-height: 10px;
        text-align: left;
      }
    }
    .envelope_record_item_right {
      margin-left: 10px;
      font-family: SFProRoundedHeavy, sans-serif;
      font-weight: 800;
      font-size: 14px;
      color: #58E8F5;
      line-height: 16px;
      text-shadow: 0px 1px 0px rgba(0,0,0,0.72);
      .envelope_record_item_right_num {
        display: flex;
        cursor: pointer;
        align-items: center;
        color: #fff;
        img {
          margin-left: 5px;
          width: 7px;
          height: 11px;
          margin-top: 3px;
        }
      }
    }
  }
}
.envelope_detail_wrap {
  display: flex;
  height: 100%;
  flex-direction: column;
  .envelope_detail_title {
    font-family: SFProRoundedHeavy, sans-serif;
    font-weight: 800;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 18px;
    text-align: left;
    margin: 20px 0 10px;
  }
  .envelope_detail_cont {
    flex: 1;
    background: #246DA6;
    border-radius: 8px;
    overflow: auto;
    padding-top: 10px;
    box-sizing: border-box;
    .envelope_detail_item {
      padding: 5px 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-family: SFProRoundedBold, sans-serif;
      .envelope_detail_left {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        background: red;
      }
      .envelope_detail_center {
        flex: 1;
        overflow: hidden;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 14px;
        text-align: left;
      }
      .envelope_detail_right {
        margin-left: 10px;
        font-weight: 800;
        font-size: 14px;
        color: #58E8F5;
        line-height: 16px;
        text-shadow: 0px 1px 0px rgba(0,0,0,0.72);
        text-align: right;
      }
    }
  }
}
