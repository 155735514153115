.d_btn {
  width: 182px;
  height: 44px;
  background: linear-gradient( 180deg, #FFC055 0%, rgba(255,92,0,0.82) 100%);
  box-shadow: 0px 6px 4px 0px rgba(106,182,216,0.67), 0px 3px 0px 0px #993900, inset 0px 0px 12px 0px #CA670A;
  border-radius: 26px;
  margin: 10px auto 5px;
  position: relative;
  transition: all .3s;
  cursor: pointer;
  &:active{
    opacity: 0.9;
    scale: 1.2;
  }
  .d_btn_dian1 {
    position: absolute;
    left: 24px;
    top: 7px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
  }
  .d_btn_dian2 {
    position: absolute;
    left: 37px;
    top: 8px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
  }
  .d_btn_border {
    width: 100%;
    height: 100%;
    border: 3px solid rgba(255,240,243,0.23);
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SFProRoundedBlack, sans-serif;
    font-weight: 900;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 21px;
    letter-spacing: 1px;
    text-shadow: -1px -1px 0 #993900, 1px -1px 0 #993900, -1px 1px 0 #993900, 1px 1px 0 #993900, 0 2px 0 #BA4800;
    text-align: center;
    font-style: normal;
  }
}
.d_btn_disable {
  background: linear-gradient( 180deg, #E5E5E5 0%, rgba(159,159,159,0.82) 100%) !important;
  box-shadow: 0px 6px 4px 0px rgba(0,0,0,0.33), 0px 3px 0px 0px #797979, inset 0px 0px 12px 0px #FFFFFF !important;
  .d_btn_border {
    text-shadow: -1px -1px 0 #909090, 1px -1px 0 #909090, -1px 1px 0 #909090, 1px 1px 0 #909090, 0 2px 0 #666666;
  }
}