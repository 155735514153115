@import "./index.scss";

.modal {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    font-family: "SFProRoundedBlack";
}

.close {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 246px;
    height: 308px;
    margin: auto;

    .close_icon {
        position: absolute;
        right: -24px;
        top: -73px;
        width: 34px;
        height: 34px;
    }
}

.bg1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('../../../../assets/images/home/lucky_light.png') no-repeat;
    background-size: 375px auto;
    background-position: center;
}

.content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 246px;
    height: 308px;
    margin: auto;
    background: url('../../../../assets/images/home/lucky_bg.png') no-repeat;
    background-size: 100% 100%;
    animation: lucky_zoomin 0.3s;
}

.bg2 {
    position: absolute;
    left: 0;
    bottom:  60px;
    margin: auto;
    width: 246px;
    height: 96px;
    background: url('../../../../assets/images/home/lucky_bg1.png') no-repeat;
    background-size: 100% auto;
}

.opened_bg2 {
    @extend .bg2;
    bottom: 20px;
}

.open {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 51px;
    margin: auto;
    width: 105px;
    height: 104px;
    background: url('../../../../assets/images/home/lucky_open.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.open_text {
    font-weight: 900;
    font-size: 22px;
    color: #FFFFFF;
    line-height: 26px;
    text-shadow: 0px 2px 0px #A1311D, 0px -1px 0 #A1311D, 1px 0px 0 #A1311D, -1px 0px 0 #A1311D;
    text-align: center;
    font-style: normal;
}

.send_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 57px;
}

.sender {
    font-weight: 900;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
}

.send_desc {
    margin-top: 6px;
    font-weight: 900;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 21px;
    text-align: center;
}

.send_value {
    margin-top: 34px;
    font-family: 'SFProRoundedHeavy';
    font-weight: 800;
    font-size: 30px;
    color: #FFD997;
    line-height: 30px;
    text-align: center;
    font-style: normal;
}

.send_coin {
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 14px;
    text-align: center;
}

.expire {
    @extend .open;
    filter: grayscale(100);
    cursor: default;

    .open_text {
        font-size: 16px;
    }
}

.expire_desc {
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 900;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 14px;
    text-align: center;
    font-style: normal;
    opacity: 0.6;
}

.coin1 {
    width: 88px;
    height: 88px;
    display: block;
    position: absolute;
    left: -34px;
    top: -34px;
}

.coin2 {
    width: 45px;
    height: 45px;
    display: block;
    position: absolute;
    top: 74px;
    right: -18px;
}

.coin3 {
    width: 47px;
    height: 47px;
    display: block;
    position: absolute;
    left: -21px;
    bottom: 27px;
}

.coin4 {
    width: 54px;
    height: 54px;
    display: block;
    position: absolute;
    right: 57px;
    bottom: 42px;
}

.coin5 {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 18px;
    width: 82px;
    height: 82px;
    z-index: 1;
}
