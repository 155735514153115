@use '../../../../theme';

.tools {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    top: 10px;
    z-index: 400;
    .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 10px;
        margin-bottom: 10px;
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            color: theme.$white-color;
            font-size: theme.$font-size;
            font-weight: bold;
            position: relative;
            > span {
                border: 2px solid theme.$primary-color;
                width: 86px;
                height: 24px;
                background: #000000;
                border-radius: 8px;
                text-align: center;
                line-height: 26px;
                margin-left: 15px;
            }
            >img {
                width: 34px;
                height: 34px;
                left: 0;
                position: absolute;
                object-fit: contain;
            }
        }
        .item:nth-child(2) {
            > img {
                width: 36px;
                height: 36px;
            }
            .addIcon {
                width: 20px;
                height: 20px;
                right: -5px;
                left: unset;
            }
        }
        .item:nth-child(3) {
            > img {
                width: 36px;
                height: 36px;
            }
        }

    }
    .left {
        position: absolute;
        left: 0;
        top: 75px;
        text-align: left;
        margin-left: 10px;
        .item {
            > img {
                width: 50px;
                height: 50px;
                margin-bottom: 10px;
            }
        }
    }
    .right {
        position: absolute;
        right: 0;
        top: 75px;
        text-align: right;
        margin-right: 10px;
        .item {
            > img {
                width: 50px;
                height: 50px;
                margin-bottom: 10px;
            }
        }
    }
}