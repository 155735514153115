$primary-color: #f488b9; // 全局主色
$link-color: #1890ff; // 链接色
$success-color: #52c41a; // 成功色
$warning-color: #faad14; // 警告色
$error-color: #f5222d; // 错误色
$white-color: #ffffff;
$bg-color: #246DA6; // 背景色
$font-size-base: 14px; // 主字号
$heading-color: rgba(0, 0, 0, 0.85); // 标题色
$text-color: rgba(0, 0, 0, 0.65); // 主文本色
$text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
$disabled-color: rgba(0, 0, 0, 0.25); // 失效色
$border-radius-base: 2px; // 组件/浮层圆角
$border-color-base: #d9d9d9; // 边框色
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // 浮层阴影
$font-size: 14px; // 默认字体大小
$card-border-color1: #FAA947;
$card-border-color2: #F1E176;
$card-border-color3: #76F178;

@mixin level1 {
  text-shadow: -1px 0px 0 #993900, 0px -1px 0 #993900, -1px 1px 0 #993900,  1px 1px 0 #993900, 0px 2px 0px #BA4800;
  text-align: center;
  font-style: normal;
  background: linear-gradient( 180deg, #FFDA70 0%, #FE8F24 100%);
  box-shadow: 0px 2px 12px 0px rgba(234,98,21,0.67), inset 0px 1px 3px 0px rgba(255,255,255,0.5);
  border-radius: 0px 15px 0px 15px;
  border: 2px solid rgba(245,232,248,0.37);
}

@mixin level2 {
  letter-spacing: 1px;
  text-shadow: -1px -1px 0 #AF9800, 1px -1px 0 #AF9800, -1px 1px 0 #AF9800,  1px 1px 0 #AF9800, 0px 2px 0px #B19A00;
  text-align: center;
  font-style: normal;
  background: linear-gradient( 180deg, #FFF9CF 0%, #DEA70D 100%);
  box-shadow: 0px 2px 12px 0px rgba(234,98,21,0.67), inset 0px 1px 3px 0px rgba(255,255,255,0.5);
  border-radius: 0px 15px 0px 15px;
  border: 2px solid rgba(245,232,248,0.37);
}

@mixin level3 {
  text-shadow: -1px -1px 0 #3DB700, 1px -1px 0 #3DB700, -1px 1px 0 #3DB700,  1px 1px 0 #3DB700, 0px 2px 0px #27B100;
  text-align: center;
  font-style: normal;
  background: linear-gradient( 180deg, #9EE59A 0%, #06A919 100%);
  box-shadow: 0px 2px 12px 0px rgba(234,98,21,0.67), inset 0px 1px 3px 0px rgba(255,255,255,0.5);
  border-radius: 0px 15px 0px 15px;
  border: 2px solid rgba(234,248,232,0.37);
}

@mixin btn-green-color {
  background: linear-gradient( 133deg, #00DD87 0%, #00A340 100%);
  //box-shadow: 0px 0px 4px 0px rgba(216,255,253,0.63);
  border-radius: 15px;
  //border: 2px solid rgba(255,240,243,0.23);
  font-weight: 900;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-shadow: -1px -1px 0 #00762E, 1px -1px 0 #00762E, -1px 1px 0 #00762E, 1px 1px 0 #00762E, 0px 2px 0px #00A340;
  text-align: center;
  font-style: normal;
}

@mixin btn-bule-color {
  background: linear-gradient(133deg, #0089DD 0%, #0028DE 100%);
  box-shadow: 0px 0px 4px 0px rgba(216,255,253,0.63);
  border-radius: 15px;
  border: 2px solid rgba(255,240,243,0.23);
  font-weight: 900;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-shadow: -1px -1px 0 #0026C9, 1px -1px 0 #0026C9, -1px 1px 0 #0026C9, 1px 1px 0 #0026C9, 0px 2px 0px #002CDE;
  text-align: center;
  font-style: normal;
}

@mixin task-card-bg {
  background: #C0E4F4;
  box-shadow: 0px 2px 4px 0px #1D649F, inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -3px 1px 0px #68D3FF;
  border-radius: 15px;
  border: 1px solid #75CAFF;
}

@mixin text-title {
  font-family: SFProRoundedBlack, sans-serif;
  font-weight: 800;
  font-size: 17px;
  color: #FFFFFF;
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0px 2px 0px rgba(0,0,0,0.72);
}
