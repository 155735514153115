.soex_app {
  text-align: center;
  color: #333;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'SFProRoundedBlack', sans-serif;
}
body {
  overscroll-behavior-y: none;
}

* {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}
div::-webkit-scrollbar {
  display: none !important;
}
.coin_icon{
  width: 34px;
  height: 34px;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: url("./assets/images/home/imomo/imomo-coin.png") no-repeat;
  background-size: contain;
}

.ant-tour-footer{
  display: none !important;
}

.ant-tour .ant-tour-inner{
  width: max-content !important;
  background-color: transparent !important;
  margin: 0 auto;
}

.step_wrap_view{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-tour .ant-tour-inner {
  width: max-content !important;
  background-color: transparent !important;
  /* margin: 0 auto;
  left: 50%;
  margin-left: -63px; */
}

.ant-tour {
  width: 100%;
  display: flex;
  justify-content: center;
}

.step_wrap_title{
  font-family: SFProRoundedBlack, sans-serif;
  font-weight: 800;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 21px;
  text-shadow: 0px 2px 0px rgba(0,0,0,0.72);
  text-align: center;
  width: 230px;
  margin-top: 20px;
  position: relative;
}

.step_wrap_title1::after {
  content: '';
  width: 54px;
  height: 78px;
  position: absolute;
  background: url(./assets/images/home/step-1.png) no-repeat;
  background-size: contain;
  /*left: -18px;*/
  /*top: -70px;*/
  left: -40px;
  top: -70px;
}
.step_wrap_title2 {
  margin-top: 0;
}
.step_wrap_title2::after {
  content: '';
  width: 54px;
  height: 78px;
  position: absolute;
  background-image: url(./assets/images/home/step-2-1.png);
  background-size: 100% 100%;
  left: -50px;
  top: -50px;
}
.step_wrap_title3 {
  margin-top: 50px;
}
.step_wrap_title3::after {
  content: '';
  width: 54px;
  height: 78px;
  position: absolute;
  background-image: url(./assets/images/home/step-3-1.png);
  background-size: 100% 100%;
  right: 0;
  top: -90px;
}
.step_wrap_title4 {
  margin-top: -150px;
}

.step_wrap_title4::after {
  content: '';
  width: 42px;
  height: 85px;
  position: absolute;
  background-image: url(./assets/images/home/step-4-1.png);
  background-size: 100% 100%;
  left: -20px;
  top: 30px;
}

.step_wrap_title6::after {
  transform: rotateY(180deg);
  left: -5px;
}

.button_wrap4 {
  margin-top: 120px !important;
}
.skip_btn{
  width: 80px;
  height: 42px;
  position: relative;
  font-weight: 800;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  transition: all .3s;
  border-radius: 8px;
  background: rgba(255,255,255,0.04);
  border: 1px solid #fff;
}

.skip_btn_title{
  position: relative;
  z-index: 999 !important;
  font-family: SFProRoundedBlack, sans-serif;
}

.skip_btn:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 78px;
  height: 35px !important;
  z-index:1;
  /* outline: 1px solid #F1F1F1; */
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: all .3s;
  border-bottom: 1px solid #fff;
  border-radius: 8px;
}

.skip_btn:active{
  scale: 1.1;
}

.button_wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.next_btn{
  /* outline: 1px solid #FF9000; */
  border: 1px solid #FF9000;
  color: #FF9000;
  background: #FF900010;
  backdrop-filter: blur(10px);
  font-family: SFProRoundedBlack, sans-serif;
  border-radius: 8px;
}

.next_btn:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index:1;
  /* outline: 1px solid #FF9000; */
  background: #FF900010;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #FF9000;
}

.gray_filter{
  filter: grayscale(100%);
}