.coin_container {
  position: relative;
  width: 100%;
  height: 100%;
}
.bag {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0; top: 0;
  .coin_wrapper{
    width: 168px;
    height: 168px;
    background: linear-gradient(270deg, #000, #222);
    box-shadow:
            inset 5px 5px 15px rgba(255, 255, 255, 0.5),
            inset -5px -5px 15px rgba(0, 0, 0, 0.3),
            5px 5px 15px rgba(0, 0, 0, 0.3),
            -5px -5px 15px rgba(255, 255, 255, 0.5);
    position: relative;
    z-index: 999;
    border-radius: 50%;
  }
  .pepeIcon {
    position: relative;
    z-index: 200;
  }
}
.coin {
  position: absolute;
  z-index: 100;
  opacity: 0;
}
