@use '../../../../theme';

.task_card {
    @include theme.task-card-bg;
    padding: 10px;
    position: relative;
    .content {
        padding: 7px 8px;
        //background: #9ACBDC;
        border-radius: 8px;
        .tag {
            width: 36px;
            height: 36px;
            position: absolute;
            right: 0px;
            top: 2px;
            z-index: 10;
        }
    }
    .title {
        @include theme.text-title;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 18px;
        > img {
            width: 18px;
            margin-right: 6px;
            flex-shrink: 0;
        }
    }
    .text {
        font-size: 13px;
        color: #fff;
        font-family: SFProRoundedHeavy, sans-serif;
        font-style: normal;
        display: flex;
        margin-top: 5px;
        align-items: center;
        justify-content: space-between;
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000; /* 四个方向的黑色阴影 */
    }
    .num {
        display: flex;
        align-items: center;
        font-family: SFProRoundedBlack, sans-serif;
        font-weight: 900;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 16px;
        letter-spacing: 1px;
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0px 2px 0px rgba(0,0,0,0.72);
        text-align: center;
        > span {
            font-size: 18px;
            margin-left: 5px;
            font-family: SFProRoundedBlack, sans-serif;
        }

        > img {
            width: 30px;
        }
    }

    .btn{
        width: 112px;
        height: 36px;
    }
    .flex_content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;
    }
}

.task {
    height: calc(100% - 10px);
    overflow-y: scroll;
    .item {
        margin-bottom: 10px;
    }
}
