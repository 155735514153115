@use '../../../../theme';

.ranking {
    height: 100%;
    display: flex;
    flex-direction: column;
    .list {
        flex: 1;
        overflow: auto;
        padding: 10px;
        border-radius: 8px;
        background-color: theme.$bg-color;
        margin-top: 20px;
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: theme.$white-color;
        font-family: SFProRoundedBold, sans-serif;
        margin-bottom: 10px;
        .item_wrap {
            display: flex;
            align-items: center;
            .item_wrap_left {
                width: 40px;
                display: flex;
                align-items: center;
                justify-self: flex-start;
            }
        }
        .num {
            align-items: center;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            font-family: SFProRoundedHeavy, sans-serif;
            text-shadow: 0px 1px 0px rgba(0,0,0,0.72);
            > img {
                width: 22px;
                height: 22px;
                margin-left: 5px;
            }
        }
        .index {
            margin-right: 16px;
            margin-left: 4px;
        }
        .indexIcon {
            width: 16px;
            height: 18px;
            margin-right: 10px;
        }
    }
    .select {
        background-color: theme.$bg-color;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        .option {
            width: 100px;
            height: 46px;
            text-align: center;
            line-height: 44px;
            font-family: SFProRounded;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 18px;
            color: #FFFFFF;
            text-align: center;
        }
        .option_active {
            line-height: 42px;
            font-weight: bold;
            font-size: 18px;
            background: #C0E4F4;
            box-shadow: 0px 2px 4px 0px #1D649F, inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -3px 1px 0px #68D3FF;
            border-radius: 15px;
            border: 1px solid #75CAFF;
            text-align: center;
            font-style: normal;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, 0px 2px 0px rgba(0,0,0,0.72);
        }
    }
}
