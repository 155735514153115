.extract_comp_wrap {
  width: 100%;
  height: 100%;
  .select_module_wrap_line {
    width: 100%;
    height: 10px;
  }
  .select_module_wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .select_module_top {
      width: 100%;
      .select_module_top_item {
        height: 36px;
        width: 100%;
        padding-left: 23px;
        box-sizing: border-box;
        .select_module_top_item_con {
          width: 100%;
          height: 100%;
          padding: 0 15px 0 20px;
          box-sizing: border-box;
          background: #246DA6;
          box-shadow: inset 0 1px 3px 0 rgba(27,52,77,0.5);
          border-radius: 8px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: SFProRoundedBlack, sans-serif;
          font-weight: 900;
          font-size: 14px;
          color: #FFFFFF;
          .select_module_top_item_val {
            text-shadow: 0 2px 0 rgba(0,0,0,0.72);
          }
          .select_module_top_item_icon {
            position: absolute;
            left: -36px;
            top: 50%;
            transform: translate(50%, -50%);
            width: 36px;
            height: 36px;
          }
        }
      }
    }
    .select_module_center {
      flex: 1;
      width: 100%;
      overflow: auto;
      .select_module_card_con {
        width: 100%;
        .select_module_card_con_top {
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          height: 64px;
          background: #9ACBDC;
          box-shadow: inset 0px 1px 3px 0px rgba(27,52,77,0.12);
          border-radius: 8px;
          .select_module_card_con_top_1 {
            font-family: SFProRoundedHeavy, sans-serif;
            font-weight: 800;
            font-size: 13px;
            color: #FFFFFF;
            line-height: 15px;
            text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black, 0 2px 0 rgba(0,0,0,0.72);
            text-align: center;
            font-style: normal;
          }
          .select_module_card_con_top_2 {
            font-family: SFProRoundedHeavy, sans-serif;
            font-weight: 800;
            font-size: 17px;
            color: #FFFFFF;
            line-height: 20px;
            text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black, 0 2px 0 rgba(0,0,0,0.72);
            text-align: center;
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 18px;
              height: 21px;
              margin-right: 16px;
            }
          }
        }
        .select_module_card_con_bot {
          margin: 10px auto 5px;
          position: relative;
          width: min-content;
          .refresh_tips{
            position: absolute;
            top: -2px;
            right: -70px;
            width: 86px;
            height: 20px;
            line-height: 20px;
            z-index: 1;
            background: linear-gradient( 180deg, #FFC055 0%, #FF8139 100%);
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.21);
            border-radius: 12px 12px 12px 0px;

            font-family: SFProRounded, SFProRounded;
            font-weight: bold;
            font-size: 10px;
            color: #FFFFFF;
            text-align: center;
          }
          .select_module_card_con_bot_con {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            img {
              width: 14px;
              height: 24px;
            }
            .select_module_card_con_x {
              margin: 0 5px;
            }
          }
        }
      }
    }
    .select_module_bot {
      font-family: SFProRoundedHeavy, sans-serif;
      font-weight: 800;
      font-size: 15px;
      color: #FFBB5A;
      line-height: 18px;
      text-shadow: 0 2px 0 rgba(0,0,0,0.72);
      text-align: center;
      padding: 10px 0 0;
      box-sizing: border-box;
    }
  }

  .extract_module_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .extract_module_top {
      flex: 1;
      overflow: auto;
      .extract_module_user {
        padding: 0 23px;
        box-sizing: border-box;
        width: calc(100% - 46px);
        margin-left: 23px;
        min-height: 34px;
        background: #44D54A;
        border-radius: 8px;
        position: relative;
        .extract_module_user_dear {
          position: absolute;
          top: 50%; left: 13px;
          transform: translate(0, -50%);
          font-family: SFProRoundedBlack, sans-serif;
          font-weight: 900;
          font-size: 14px;
          color: #05922B;
          line-height: 16px;
          text-shadow: -1px -1px 0 rgba(255,255,255,0.6), 1px -1px 0 rgba(255,255,255,0.6), -1px 1px 0 rgba(255,255,255,0.6), 1px 1px 0 rgba(255,255,255,0.6);
          text-align: left;
        }
        .extract_module_user_name {
          width: calc(100% - 80px);
          min-height: 34px;
          margin-left: 40px;
          font-family: SFProRoundedBlack, sans-serif;
          font-weight: 900;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 1px;
          text-shadow: 0px 2px 0px rgba(0,0,0,0.72);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .extract_module_text {
        width: 100%;
        font-family: SFProRoundedBold, sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        margin: 20px 0 10px;
      }
      .extract_module_net {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2px 8px;
        box-sizing: border-box;
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0 2px 0 rgba(0,0,0,0.72);
        text-align: left;
        position: relative;
        > img {
          width: 11px;
          height: 16px;
          transition: transform 0.15s linear;
          transform: rotate(0deg);
        }
        .extract_module_net_items {
          width: calc(100% + 22px);
          margin-left: -11px;
          position: absolute;
          left: 0; right: 0;
          top: 34px;
          max-height: 0;
          z-index: 1000;
          overflow: auto;
          background: #9ACBDC;
          box-shadow: inset 0px 1px 3px 0px rgba(27,52,77,0.12);
          border-radius: 15px;
          transition: max-height 0.1s linear;
          .extract_module_net_item {
            font-family: SFProRoundedHeavy, sans-serif;
            font-weight: 800;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 19px;
            text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0 2px 0 rgba(0,0,0,0.72);
            text-align: left;
            font-style: normal;
            padding: 8px 20px;
            box-sizing: border-box;
          }
          .extract_module_net_item:first-child {
            padding-top: 15px;
          }
          .extract_module_net_item:last-child {
            padding-bottom: 15px;
          }
        }
      }
      .select_input {
        height: 90px;
        background: #C0E4F4;
        box-shadow: 0px 2px 4px 0px #1D649F, inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -3px 1px 0px #68D3FF;
        border-radius: 15px;
        border: 1px solid #75CAFF;
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0 2px 0 rgba(0,0,0,0.72);
        text-align: left;
        padding: 10px 20px;
        box-sizing: border-box;
      }
      .select_input_none {
        height: 90px;
        background: #C0E4F4;
        box-shadow: 0px 2px 4px 0px #1D649F, inset 0px 1px 3px 0px rgba(255,255,255,0.5), inset 0px -3px 1px 0px #68D3FF;
        border-radius: 15px;
        border: 1px solid #75CAFF;
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-align: left;
        padding: 10px 20px;
        box-sizing: border-box;
        text-shadow: none;
      }
      .select_input_none::placeholder {
        font-family: SFProRoundedHeavy, sans-serif;
        font-weight: 800;
        font-size: 16px;
        color: #7BB4C2;
        text-align: left;
      }
      .select_total_cell {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .select_total_cell_left {
          font-family: SFProRoundedBold, sans-serif;
          font-weight: bold;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 16px;
        }
        .select_total_cell_right {
          font-family: SFProRoundedHeavy, sans-serif;
          font-weight: 800;
          font-size: 15px;
          color: #FFFFFF;
          text-align: right;
          line-height: 18px;
          text-shadow: 0px 2px 0px rgba(0,0,0,0.72);
        }
      }
    }
    .select_bot_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0 0;
      box-sizing: border-box;
    }
  }

  .record_module_wrap {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px 0;
    box-sizing: border-box;
    .record_item {
      margin-top: 10px;
      width: 100%;
      .record_item_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 9px;
        box-sizing: border-box;
        margin-bottom: 15px;
        position: relative;
        .record_item_top_left {
          flex: 1;
          margin-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: SFProRoundedHeavy, sans-serif;
          font-weight: 800;
          font-size: 17px;
          color: #FFFFFF;
          line-height: 20px;
          text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000, 0 2px 0 rgba(0,0,0,0.72);
          text-align: left;
        }
        .record_item_refuse {
          position: absolute;
          top: -13px;
          right: 0;
          width: 18px;
          height: 18px;
        }
        .record_status_success {
          font-family: SFProRoundedHeavy, sans-serif;
          font-weight: 800;
          font-size: 17px;
          color: #44D54A;
          line-height: 20px;
          text-stroke: 1px #FFFFFF;
          text-align: left;
          font-style: normal;
          -webkit-text-stroke: 1px #FFFFFF;
        }
      }
      .record_item_bot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-bottom: 5px;
        box-sizing: border-box;
        .record_item_bot_left {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: SFProRoundedHeavy, sans-serif;
          font-weight: 800;
          font-size: 12px;
          color: #000000;
          line-height: 14px;
          text-align: left;
          margin-right: 20px;
          span {
            text-decoration: underline;
          }
        }
        .record_item_wrap_btn {
          position: absolute;
          right: 0;
          bottom: 0px;
        }
      }
    }
  }
}
